import useTranslation from 'next-translate/useTranslation';

import { CustomNodeTypes } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import { useModalContext } from '@admin/organisms/BaseModal';
import { TranslationKey } from '@admin/utils/TranslationKey';
import { Icon } from '@common/atoms/Icon';

import styles from './InsertIframe.module.scss';

interface Props {
    onClick: () => void;
}

export const InsertIframe = ({ onClick = () => {} }: Props) => {
    const { openModal } = useModalContext(CustomNodeTypes.iframe);
    const __translate = useTranslation(TranslationKey.richEditor).t;

    const handleOnClick = () => {
        openModal();
        onClick();
    };

    return (
        <button
            onClick={handleOnClick}
            className={styles.InsertIframe}
            aria-label={__translate('insertIframe.button')}
        >
            <Icon.iframe
                svgProps={{
                    width: 29,
                    height: 21,
                    viewBox: '0 0 29 21',
                }}
            />
            <span>{__translate('insertIframe.button')}</span>
        </button>
    );
};
