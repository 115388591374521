import useTranslation from 'next-translate/useTranslation';

import { CustomNodeTypes } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import { useModalContext } from '@admin/organisms/BaseModal';
import { TranslationKey } from '@admin/utils/TranslationKey';
import { Icon } from '@common/atoms/Icon';

import styles from '../InsertMatchDetailSection/InsertMatchDetailSection.module.scss';

interface Props {
    onClick: () => void;
}

export const InsertMatchBlock = ({ onClick = () => {} }: Props) => {
    const { openModal } = useModalContext(CustomNodeTypes.matchBlock);
    const __translate = useTranslation(TranslationKey.richEditor).t;

    const handleOnClick = () => {
        openModal();
        onClick();
    };

    return (
        <button
            onClick={handleOnClick}
            className={styles.InsertMatchDetailSection}
            aria-label={__translate('insertMatchBlock.button')}
        >
            <Icon.footballPitch
                svgProps={{
                    width: 20,
                    height: 20,
                    viewBox: '0 0 20 20',
                }}
            />
            <span>{__translate('insertMatchBlock.button')}</span>
        </button>
    );
};
