import { ArticleBlock } from '@admin/molecules/RichEditor/Editor/atoms/ArticleBlock';
import { CustomNodeTypes } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import { ModalActions, useModalContext } from '@admin/organisms/BaseModal';

import { EmbedIframeModal } from './EmbedIframeModal';
import { IframeNodeData } from './IframeNode';

interface Props extends IframeNodeData {
    onChange: (data: IframeNodeData) => void;
    onDelete: () => void;
}

export const IframeArticleBlock = ({ onChange, onDelete, iframeUrl, aspectRatio, type }: Props) => {
    const { openModal } = useModalContext(CustomNodeTypes.iframe);

    if (!iframeUrl) {
        return null;
    }

    return (
        <div data-theme="admin">
            <ArticleBlock onClick={openModal} onDelete={onDelete} customNodeType={CustomNodeTypes.iframe}>
                <span>{iframeUrl}</span>
            </ArticleBlock>
            <EmbedIframeModal
                formData={{ iframeUrl, aspectRatio, type }}
                onSubmit={onChange}
                action={ModalActions.update}
            />
        </div>
    );
};
